@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components{

   
    .text-large{
        @apply text-2xl md:text-3xl;
    }

    .text-subtitle{
        @apply text-base text-gray-200 !important;
    }

    .text-medium{
        @apply text-xl md:text-2xl;
    }

    .text-small{
        @apply text-base md:text-lg text-justify leading-8 text-gray-300;
    }

    .flex-col-start{
        @apply flex flex-col items-start;
    }
    .frm label{
        @apply block mb-3 text-lg capitalize;
    }

    .frm input, .frm textarea{
        @apply bg-gray-300 h-12 text-gray-900 indent-3 text-lg w-full  xl:w-8/12 mb-3;
    }

    .frm textarea{
        @apply h-auto;
    }

    .box-grad {


        @apply bg-[#131C31] border border-[#222F43] ;
        border-radius: 8px;
        box-shadow: 0px 0px 8px #131C31, -0px -0px 8px #131C31;
        position: relative;
        transition: 300ms linear;
        cursor: pointer;
    
    }
    

    .card-blog-1 {
        padding: 20px;
        position: relative;
        /* width: 100%; */
        border-radius: 16px;
        border-width: 1px;
        border-style: solid;
        /* margin-bottom: 30px; */
        border: 1px solid #222F43;
        background-color: #131C31;
    }
    
    .bg-box {
        @apply dark:bg-gradient-to-r from-[#131C31] ;
        /* background: linear-gradient(to right bottom, #15171a, #181b1f); */
        box-shadow: 0px 0px 8px #1c1e22, -0px -0px 8px #131C31;
    }
    
    .box-grad .icon {
        position: absolute;
        right: 16px;
        bottom: 16px;
        opacity: .4;
        transition: 300ms linear;
    }
    
    .box-grad:hover .icon {
        opacity: 1;
    }
    
    .box-grad:hover {
        transform: scale(1.01);
    }
    
    .avatar {
        border-radius: 30px 4px;
        position: relative;
    }
    
    
    
    .bg-grad {

        background: #131C31;
        background-image: linear-gradient(to right, #131C31,
        #131C31, #131C31);
        border: 1px solid #304058;
    }
    
    .bg-avatar {
        background-image: linear-gradient(to right, #5a78ea, #5a8ff2, #64a5f8, #79b9fb, #93ccfc, #99d0fa, #9fd4f9, #a6d8f7, #95cff2, #83c6ed, #70bee8, #5ab5e3);
    }

    .swiper-button-prev:after, .swiper-button-next:after {
        font-size: 1.2rem !important;
        background-color: rgba(10, 11, 11, 0.45) !important;
        padding: .7rem 1.1rem;
        border-radius: 50%;
        font-weight: 700;
        color: white !important;
        /* box-shadow: 0px 0px 4px #1c1e22, -0px -0px 4px #262a2e; */
    }
}
