/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800&family=Noto+Sans+Arabic&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400&family=Prosto+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
:root {
    --background-color-1: linear-gradient(145deg, #1e2024, #23272b);
    --background-color-2: #212428;
    --shadow-1: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
    --shadow-2: inset 21px 21px 19px #181a1d, inset -21px -21px 19px #202225;
}

html {
    scroll-behavior: smooth;
    overflow:'hidden';
}

body {

   
    /* font-family: 'Inter', sans-serif; */

     /* font-family: 'Prosto One', cursive;  */
    font-family: 'Lato', sans-serif; 
    overflow:'hidden';
    /* font-family: "Noto Sans", sans-serif; */

    color: #94A9C9;
    background-color: #0F172A;
    /* font-family: "Noto Sans"; */
    font-size: 14px;
    line-height: 18px;
    font-style: normal;
    font-weight: 400;
    position: relative;


}

/* .loader {
    display: flex;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
} */

.loader .letter {
    animation: fade 1.7s infinite;
}

.letter:nth-child(1) { animation-delay: 0s; }
.letter:nth-child(2) { animation-delay: 0.2s; }
.letter:nth-child(3) { animation-delay: 0.4s; }
.letter:nth-child(4) { animation-delay: 0.6s; }
.letter:nth-child(5) { animation-delay: 0.8s; }
.letter:nth-child(6) { animation-delay: 1s; }
.letter:nth-child(7) { animation-delay: 1.2s; }
.letter:nth-child(8) { animation-delay: 1.4s; }

@keyframes fade {
    0%, 100% { opacity: 0;  }
    50% { opacity: 1; }
}
.social-link {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #fff;
    font-size: 20px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    background: linear-gradient(120deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.01));
    transition: .3s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-link:hover,
.theme-btn:hover {
    background: #ffffff;
    color: #0F0F0F;
}

.theme-btn {
    background: #323332;
    color: #fff;
    display: inline-block;
    transition: .3s;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 500;
    padding: 12px 30px;
    text-align: center;
}

.sticky-top {
    position: sticky;
    top: 3%;
}


.swiper-button-prev:after, .swiper-button-next:after {
    font-size: 1.2rem !important;
    background-color: #212428;
    padding: .7rem 1.1rem;
    border-radius: 50%;
    font-weight: 700;
    color: white;
    box-shadow: 0px 0px 4px #1c1e22, -0px -0px 4px #262a2e;
}

.swiper-model{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.05);
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 400ms linear;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-200%);
    transform-origin: top left;
}

.swiper-model.show{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}


.skillsIcon i{
    font-size: 50px !important;
}

@media (max-width:576px){
    .skillsIcon i{
        font-size: 36px !important;
    }
}




.cover-home1 {
    position: fixed;
}
.cover-home1 .container {
    position: relative;
    z-index: 2;
}
.cover-home1::after {
    height: 800px;
    width: 500px;
    content: "";
    z-index: 1;
    background: url("../images/template/shadow-3.svg") no-repeat right center;
    position: fixed;
    right: 0px;
    top: 0px;
}
.cover-home1::before {
    height: 1500px;
    width: 500px;
    content: "";
    z-index: 1;
    background: url("../images/template/shadow-1.svg") no-repeat right center;
    position: fixed;
    left: 0px;
    top: 100px;
    opacity:0.5
}

.banner-img::before {
    content: "";
    position: absolute;
    bottom: -200px;
    right: -140px;
    height: 500px;
    width: 500px;
    background: url("../images/template/shadow-2.svg") no-repeat center;
    background-size: contain;
    z-index: 1;
}

.form-control {
    border-radius: 8px;
    padding: 20px;
    border-width: 1px;
    border-style: solid;
    color: #7F92B0;
}

.border-input {
    border-color: #222F43 !important;
}

.bg-gray-850 {
    background-color: #131C31;
}

.btn-linear {
    background: rgb(14, 165, 234);
    background: linear-gradient(90deg, rgb(14, 165, 234), rgb(6, 181, 181) 51%, rgb(8, 141, 202)) var(--x, 0) / 200%;
    color: #ffffff;
    transition: 0.5s;
}